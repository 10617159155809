<template>
    <div>
      <b-overlay :show="show" no-wrap fixed z-index="9999">
        <template v-slot:overlay>
            <div class="d-flex align-items-center">
              <b-spinner small type="grow" variant="dark"></b-spinner>
              <b-spinner type="grow" variant="dark"></b-spinner>
              <b-spinner small type="grow" variant="dark"></b-spinner>
            </div>
          </template>
      </b-overlay>
      <b-button variant="success" v-b-modal.new-modal>ثبت دسته بندی</b-button>
      <b-card title="دسته بندی ها" class="mt-3">
        <b-table
          responsive
          striped
          hover
          v-if="items.data"
          :fields="table_fields"
          :items="items.data.data">

          <template v-slot:cell(created_at)="data">
              {{ data.item.created_at | persianDate }}
          </template>
          <template v-slot:cell(status)="data">
            <span class="badge badge-success" v-if="data.item.status == 1">فعال</span>
            <span class="badge badge-light" v-else>غیرفعال</span>
          </template>

          <template v-slot:cell(edit)="data">
              <button class="btn btn-primary" @click="editItem(data.index)"><i class="fa fa-edit"></i></button>
          </template>
          <template v-slot:cell(delete)="data">
              <button class="btn btn-danger" @click="deleteItem(data.item.id)"><i class="fa fa-close"></i></button>
          </template>
        </b-table>
        <div class="alert alert-danger text-right" v-if="items.data && items.data.data == ''">
          <span class="text-right">موردی یافت نشد ! </span>
        </div>
      </b-card>

      <pagination :limit="2" v-if="items.data" :data="items.data" @pagination-change-page="loadItems"></pagination>

      <b-modal id="new-modal" title="ثبت دسته بندی" hide-footer>
        <div>
          <form @submit.prevent="newItem" id="new-item">
            <b-form-group label="اسم" label-for="name">
              <b-form-input id="name" name="name" :disabled="disabled"></b-form-input>
            </b-form-group>

            <div class="form-group">
              <label class="custom-switch">
                  <input type="checkbox" name="status" class="custom-switch-input" v-model="selected_status" :disabled="disabled">
                  <span class="custom-switch-indicator"></span>
                  <span class="custom-switch-description">وضعیت</span>
              </label>
            </div>

            <div class="clearfix">
              <button class="btn btn-success float-right" type="submit" :disabled="disabled">ثبت</button>
              <b-button class="float-left" variant="white" type="button"  @click="$root.$emit('bv::hide::modal', 'new-modal')">بستن</b-button>
            </div>
          </form>
        </div>
      </b-modal>

      <b-modal id="edit-modal" title="ویرایش دسته بندی" hide-footer>
        <div v-if="edit.id">
          <form @submit.prevent="itemEdited" id="edit-item">
            <b-form-group label="اسم" label-for="edit-name">
              <b-form-input id="edit-name" name="name" v-model="edit.name" :disabled="disabled"></b-form-input>
            </b-form-group>

            <div class="form-group">
              <label class="custom-switch">
                  <input type="checkbox" name="status" class="custom-switch-input" v-model="edit.status" :disabled="disabled">
                  <span class="custom-switch-indicator"></span>
                  <span class="custom-switch-description">وضعیت</span>
              </label>
            </div>
            <input type="hidden" value="PATCH" name="_method">
            <div class="clearfix">
              <button class="btn btn-primary float-right" type="submit" :disabled="disabled">ویرایش</button>
              <b-button class="float-left" variant="white" type="button"  @click="$root.$emit('bv::hide::modal', 'edit-modal')">بستن</b-button>
            </div>
            
          </form>
        </div>
      </b-modal>
    </div>
  </template>

<script>
import mixins from '../mixins/mixins'

export default {
  mixins: [mixins],
  data () {
    return {
      url: '/api/admin/blog-categories',
      title: 'اخبار',
      table_fields: [
        { key: 'id', label: 'شناسه ' },
        { key: 'name', label: 'اسم' },
        { key: 'status', label: 'وضعیت' },
        { key: 'created_at', label: 'تاریخ ثبت' },
        { key: 'edit', label: 'ویرایش ' },
        { key: 'delete', label: 'حذف' }
      ],
      selected_status:'1'
    }
  },
  methods: {

  },
  created () {
    this.loadItems()
  }
}
</script>
